<script lang="ts" setup>
import { cn } from '../helpers/common';
import type { InputType, InputStyle, InputSize, InputIconPosition } from '../types/input';

import { computed, useSlots, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    inputStyle?: InputStyle;
    type?: InputType;
    size?: InputSize;
    disable?: boolean;
    placeholder?: string;
    name?: string;
    value?: any;
    error?: string;
    classes?: string;
    iconPosition?: string;
    iconClass?: InputIconPosition;
  }>(),
  {
    inputStyle: 'primary',
    type: 'text',
    size: 'medium',
    disable: false,
    error: '',
    iconPosition: 'first',
  },
);
const slots = useSlots();

const inputRef = ref<HTMLInputElement>();
function focus() {
  if (inputRef.value) {
    inputRef.value.focus();
  }
}
defineExpose({
  focus,
});

//=================== Styles =====================
const iconClasses = computed(() => {
  const classes = ['absolute', 'text-center'];
  switch (props.size) {
    case 'large':
      classes.push('[&>svg]:w-16', '[&>svg]:h-16');
      break;
    case 'medium':
      classes.push('[&>svg]:w-20', '[&>svg]:h-20');
      break;
    case 'small':
      classes.push('[&>svg]:w-16', '[&>svg]:h-16');
      break;
  }

  if (props.iconPosition === 'last') {
    classes.push('right-0');
  }

  return classes.join(' ');
});
const inputClasses = computed(() => {
  const classes = [
    'w-full',
    'rounded-medium',
    'transition-colors',
    'duration-200',
    'outline-0',
    '!px-8',
    'caret-primary-300',
  ];

  switch (props.inputStyle) {
    case 'primary':
      if (props.disable) {
        classes.push(
          'placeholder:text-dark-disabled border-dark-300 disabled:cursor-not-allowed disabled:border-dark-200',
        );
      } else {
        classes.push('placeholder:text-14 placeholder:text-light-disabled bg-white border text-light-high');
      }
      if (props.error !== '') {
        classes.push('border-red-300 hover:border-red-300 focus:border-red-300');
      } else {
        classes.push('border-transparent hover:border-transparent focus:border-primary-300');
      }
      break;
    case 'secondary':
      if (props.disable) {
        classes.push(
          'placeholder:text-dark-disabled border-dark-300 disabled:cursor-not-allowed disabled:border-dark-200',
        );
      } else {
        classes.push(
          'placeholder:text-dark-disabled border border-dark-400 focus:border-primary-300 focus:bg-dark-400 hover:border-dark-200 hover:bg-dark-200 disabled:border-dark-200 disabled:bg-dark-200 text-dark-high bg-dark-400',
        );
      }
      break;
  }
  switch (props.size) {
    case 'large':
      classes.push('h-40 text-14 p-12 leading-4');

      break;
    case 'medium':
      classes.push('h-36 text-12 p-10 leading-4');
      break;
    case 'small':
      classes.push('h-32 text-12 p-8 leading-4');
      break;
  }
  if (slots.icon !== undefined) {
    if (props.iconPosition === 'first') {
      classes.push('!pl-32');
    } else {
      classes.push('!pr-32');
    }
  }
  return classes.join(' ');
});

//=================== End Styles =====================

//=================== Emit Function =====================
const emit = defineEmits<{
  (e: 'change', value: string): void;
  (e: 'on-change', value: string): void;
  (e: 'handleBlur', value: string): void;
}>();
//=================== End Emit Function =====================

//=================== Methods ====================

const change = ($event: Event | KeyboardEvent | any) => {
  emit('change', $event?.target?.value?.trim());
};
const onChange = ($event: Event | KeyboardEvent | any) => {
  emit('on-change', $event?.target?.value.trim());
};
const handleOnInputBlur = ($event: Event | KeyboardEvent | any) => {
  emit('handleBlur', $event?.target?.value);
};
//=================== End Methods =====================
</script>

<template>
  <div class="w-full">
    <slot name="label"></slot>
    <div class="relative flex w-full items-center">
      <div v-if="!!slots['icon']" :class="cn(iconClasses, iconClass)" class="flex w-32 justify-center">
        <slot name="icon"></slot>
      </div>
      <slot name="clear"></slot>
      <input
        ref="inputRef"
        class="text-ellipsis focus:outline-none"
        :placeholder="placeholder"
        :class="cn(inputClasses, classes)"
        :type="type"
        :value="value"
        v-bind="$attrs"
        :disabled="disable"
        @change="change"
        @input="onChange"
        @blur="handleOnInputBlur" />
    </div>
    <div v-if="error !== ''" class="text-12 font-regular flex flex-row gap-8 pt-10 text-red-300">
      <g-base-icon name="error" width="16" height="16" view-box="0 0 16 16" custom-class="text-red-300"></g-base-icon
      ><span>{{ error }}</span>
    </div>
    <slot name="description"></slot>
  </div>
</template>
