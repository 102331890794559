<script lang="ts" setup>
import { TransitionRoot, TransitionChild, Dialog } from '@headlessui/vue';
import { ref } from 'vue';
import GBaseIcon from './GBaseIcon.vue';
import type { ButtonType } from '../types/button';
import GButton from './GButton.vue';
import { useDraggable, onClickOutside } from '@vueuse/core';
import { cn } from '../helpers/common';
import { GTooltip } from '..';
import type { Placement } from '@floating-ui/core';

const emits = defineEmits(['ok', 'cancel', 'close', 'refresh', 'outside']);

withDefaults(
  defineProps<{
    type?: 'normal' | 'confirm';
    hideOverlay?: boolean;
    contentFixed?: boolean;
    hideHeader?: boolean;
    hideActions?: boolean;
    hideBtnCannel?: boolean;
    showBtnClose?: boolean;
    showBtnRefresh?: boolean;
    modalClass?: string;
    containerClass?: string;
    headerClass?: string;
    bodyClass?: string;
    footerClass?: string;
    dialogClass?: string;
    headerTitleClass?: string;
    loading?: boolean;
    refreshing?: boolean;
    isOpen?: boolean;
    btnOkDisable?: boolean;
    btnCancelType?: ButtonType;
    btnOkType?: ButtonType;
    labelCancel?: string;
    labelOk?: string;
    isDraggable?: boolean;
    initialVariable?: {
      x: number;
      y: number;
    };
    hrClass?: string;
    showHr?: boolean;
    cancelBtnClass?: string;
    okBtnClass?: string;
    okBtnOnlyIcon?: boolean;
    closeBtnClass?: string;
    closeTooltipContent?: string;
    placementCloseTooltip?: Placement;
  }>(),
  {
    type: 'normal',
    btnCancelType: 'grey',
    btnOkType: 'primary',
    modelValue: false,
    labelCancel: 'Cancel',
    labelOk: 'Save',
    showBtnClose: false,
    showBtnRefresh: false,
    isDraggable: false,
    showHr: false,
    placementCloseTooltip: 'left',
  },
);

const focusRef = ref(null);
const target = ref(null);
const modalContainer = ref<HTMLElement>();
onClickOutside(target, () => emits('outside'));

const { style } = useDraggable(modalContainer, {
  initialValue: { x: 280, y: 50 },
  // preventDefault: true,
});
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog
      as="div"
      :class="cn('inset-0 z-50 flex items-center justify-center', { fixed: !contentFixed }, dialogClass)"
      :initial-focus="focusRef"
      @close="$emit('close')">
      <div v-if="!hideOverlay" class="fixed inset-0 bg-black opacity-60" aria-hidden="true" @click="$emit('close')" />
      <div
        ref="target"
        :class="cn('fixed z-50 max-h-[90%] max-w-[90%]', containerClass)"
        :style="[isDraggable ? style : {}]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <div
            :class="
              cn(
                'rounded-large shadow-2dp flex h-full w-full min-w-[400px] transform flex-col overflow-hidden text-left align-middle transition-all',
                {
                  'shadow-modal': hideOverlay,
                  'w-[400px]': type === 'confirm',
                },
                modalClass,
              )
            ">
            <div
              v-if="!hideHeader"
              ref="modalContainer"
              :class="
                cn(
                  'bg-light-100 text-light-high relative z-20 flex items-center p-16',
                  {
                    'cursor-move': isDraggable,
                  },
                  headerClass,
                )
              ">
              <div class="text-16 w-full truncate font-medium leading-6" :class="headerTitleClass">
                <slot name="title"></slot>
              </div>
              <button
                v-if="showBtnRefresh"
                class="bg-light-100 !text-light-high hover:bg-light-400 rounded-medium flex h-24 w-24 flex-none items-center justify-center !p-4"
                @click="$emit('refresh')">
                <g-base-icon
                  v-if="refreshing"
                  class="text-light-low"
                  :class="{ 'animate-spin': refreshing }"
                  name="small-loading"
                  width="16"
                  height="16" />
                <g-base-icon v-else name="refresh" width="16" height="16" view-box="0 0 16 16" />
              </button>
              <div v-if="showBtnRefresh" class="border-light-400 mx-8 h-full border-l"></div>
              <template v-if="showBtnClose">
                <g-tooltip v-if="closeTooltipContent" :placement="placementCloseTooltip" class="flex py-0 pl-0">
                  <button
                    tabindex="-1"
                    class="bg-light-100 !text-light-high rounded-medium flex h-24 w-24 flex-none items-center justify-center outline-none hover:bg-black/10"
                    :class="closeBtnClass"
                    @click="$emit('close')">
                    <g-base-icon name="close" width="16px" height="16px" />
                  </button>
                  <template #content>
                    <span class="text-12 flex items-center">{{ closeTooltipContent || 'Cancel' }}</span>
                  </template>
                </g-tooltip>
                <button
                  v-else
                  tabindex="-1"
                  class="bg-light-100 !text-light-high hover:bg-light-400 rounded-medium flex h-24 w-24 flex-none items-center justify-center outline-none"
                  :class="closeBtnClass"
                  @click="$emit('close')">
                  <g-base-icon name="close" width="16px" height="16px" />
                </button>
              </template>
            </div>
            <hr v-if="showHr" :class="hrClass" />
            <div :class="cn('bg-light-100 relative z-10 flex-grow overflow-y-auto', bodyClass)">
              <slot></slot>
            </div>
            <hr v-if="showHr" :class="hrClass" />
            <div
              v-if="!hideActions"
              :class="cn('bg-light-100 relative flex items-center justify-end px-12 py-16', footerClass)">
              <slot name="footer"></slot>
              <g-button
                v-if="!hideBtnCannel"
                button-type="button"
                :type="btnCancelType"
                :class="cn('text-light-high border border-[#848484] !bg-white hover:!bg-[#E5E5E5]', cancelBtnClass)"
                @click="$emit('cancel')"
                >{{ labelCancel }}</g-button
              >

              <g-button
                ref="focusRef"
                button-type="button"
                :class="cn('ml-8', okBtnClass)"
                :type="btnOkType"
                :disable="btnOkDisable"
                :loading="loading"
                :only-icon="okBtnOnlyIcon"
                @click="!btnOkDisable && $emit('ok')">
                {{ labelOk }}
              </g-button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
