<script setup lang="ts">
import { reactive } from 'vue';
import GLoadingPlaceholder from '../../base/GLoadingPlaceholder.vue';

type Props = {
  numberElements: number;
};

const props = withDefaults(defineProps<Props>(), {
  numberElements: 1,
});

const loadingElements = reactive<number[]>(Array.from(Array(props.numberElements).keys()));
</script>

<template>
  <div class="w-full">
    <div
      v-for="item in loadingElements"
      :key="item"
      class="bg-light-100 border-light-400 rounded-medium mb-24 flex h-[80px] items-center justify-between border">
      <div class="flex h-full items-center">
        <div class="ml-16">
          <div class="flex items-center">
            <g-loading-placeholder width="360px" height="16px" />
          </div>
          <div class="mt-4">
            <g-loading-placeholder width="150px" height="12px" />
          </div>
        </div>
      </div>
      <div class="flex items-center">
        <div class="mr-28">
          <g-loading-placeholder width="120px" height="12px" />
        </div>
        <div class="mr-8">
          <g-loading-placeholder width="40px" height="40px" />
        </div>
        <div class="mr-16">
          <g-loading-placeholder width="40px" height="40px" />
        </div>
      </div>
    </div>
  </div>
</template>
